<style scoped lang="less">
.index {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
  align-items: center;
  max-width: 400px;
  margin: 40px auto;
  height: auto;
  min-height: unset;
  padding: 100px 0;
  background-color: #FFF;
}
.title {
  margin-top: 57px;font-size: 20px;color: #4A4A4A;font-weight: bold;line-height: 27px;
}
.sub-title {
  font-size: 18px;line-height: 25px;color: #6B6B6B;
}
.back {
  margin-top: 62px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
  background-color: #FB6D8F;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  height: 55px;
  width: 290px;
  cursor: pointer;
}
</style>

<template>
  <div class="index">
    <img src="/static/images/null.png" style="width: 167px;"/>
    <div class="title">开发中</div>
    <div class="sub-title">敬请期待！</div>
    <div class="back" @click="$router.back()">返 回</div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '开发中' },
    subTitle: { type: String, default: '敬请期待！' }
  }
}
</script>